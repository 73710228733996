import React, { createContext, useContext, useState, ReactNode } from 'react';

interface UserContextProps {
    email: string;
    first_name: string;
    last_name: string;
    age: number;
    sex_at_birth: string;
    country_of_residence: string;
    ethnicity: string;
    password: string;
    confirm_password: string;
    terms: boolean;
    user_health_info: {
        weight: number;
        weight_unit: string;
        height: number;
        height_unit: string;
    }
    updateUserDetails: (newDetails: Partial<UserContextProps>) => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [userDetails, setUserDetails] = useState<UserContextProps>({
        first_name: "",
        last_name: "",
        email: "",
        sex_at_birth: "",
        country_of_residence: "",
        ethnicity: "",
        password: "",
        confirm_password: "",
        terms: false,
        age: 0,
        user_health_info: {
            weight: 0,
            weight_unit: '',
            height: 0,
            height_unit: '',
        },
        updateUserDetails: (newDetails) => setUserDetails(prev => ({ ...prev, ...newDetails })),
    });

    return (
        <UserContext.Provider value={{ ...userDetails, updateUserDetails: userDetails.updateUserDetails }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};
