import React, { useState } from 'react'
import Logo from "../../assets/images/logo.png"
import { Formik, Form, Field } from "formik";
import { Container, Card, Button, Image, Row } from "react-bootstrap";
import * as Yup from "yup";
import CloseBtn from "../../assets/images/close-btn.png"
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { signinAsync } from "../../slices/auth/authSlice";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface SignInValues {
    email: string;
    password: string;
}

function SignIn() {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return (
        <Container fluid>
            <div className="auth-bg">
                <Card className="auth-card-2 text-center">
                    <Link to="/" className='d-inline-flex justify-content-end'>
                        <img src={CloseBtn} alt="close" />
                    </Link>
                    <div className='text-center'>
                        <Image src={Logo} width={63} height={80} />
                    </div>
                    <h4 className="auth-text-1 mt-2">Sign In</h4>
                    <p className="auth-text-2">Please sign in to continue</p>
                    <div className="text-start">
                        <Formik
                            initialValues={{
                                email: "",
                                password: "",
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email("Invalid email")
                                    .required("Email is required"),
                                password: Yup.string()
                                    .required("No password provided.")
                                    .min(6, "Password is too short - should be 6 chars minimum."),
                            })}
                            onSubmit={async (values: SignInValues, { setSubmitting }) => {
                                try {
                                    const response = await dispatch(signinAsync(values)).unwrap();
                                    console.log("Signin successful:", response.tokens.refresh_token);
                                    navigate('/dashboard');
                                } catch (error) {
                                    console.error("Signin failed:", error);
                                } finally {
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({
                                errors,
                                touched,
                                handleSubmit,
                                isSubmitting,
                                values,
                                handleChange,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-2">
                                        <Field
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            className={`input-field ${touched.email && errors.email ? "is-invalid" : ""
                                                }`}
                                            required
                                        />
                                    </Row>
                                    <Row className="mb-2 position-relative">
                                        <Field
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            placeholder="Password"
                                            className={`input-field ${touched.password && errors.password ? "is-invalid" : ""
                                                }`}
                                            required
                                        />
                                        <span
                                            className="password-toggle-icon"
                                            onClick={() => setShowPassword(!showPassword)}
                                            style={{
                                                position: 'absolute',
                                                left: '280px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </Row>

                                    <div className="d-grid mt-4 sign-in-btn">
                                        <Button
                                            type="submit"
                                            className="border-0 bg-color-1 "
                                        >
                                            <span className="text-center">Sign in</span>
                                        </Button>
                                    </div>
                                    <div className="text-center mt-3">
                                        <Link to="/forgot-password" className="link-styles auth-text-2">
                                            Forgot password?
                                        </Link>
                                    </div>
                                    <div className="text-center mt-5 auth-text-2">
                                        <Link to="/sign-up" className="link-styles">
                                            Don’t have an account?{" "}
                                            <span className="text-color-1"> Sign up </span>
                                        </Link>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Card>
            </div>
        </Container>
    )
}

export default SignIn