import React from "react";
import { ReactNode } from "react";
import SideBar from "../sidebar/sidebar";
import RightSidebar from "../sidebar/right-sidebar";

interface Props {
    children: ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {

    return (
        <div className="main-layout">
            <div className="layout-content">
                <SideBar />
                <main className="content">
                    <div>{children}</div>
                </main>
                <RightSidebar />
            </div>
        </div>
    );
};

export default Layout;
