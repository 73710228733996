import React, { useState, useEffect } from 'react';
import { Image, Row, Col, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/dash-logo.png";
import User from "../../assets/images/user.png";
import Overview from "../../assets/images/overview.png";
import MealPlanner from "../../assets/images/mealplanner.png";
import Reminders from "../../assets/images/reminder.png";
import Reports from "../../assets/images/report.png";
import Calendar from "../../assets/images/calendar.png";
import Settings from "../../assets/images/settings.png";
import CollapseIcon from "../../assets/images/collapse.png";
import Logout from "../../assets/images/logout.png";
import { signoutAsync } from "../../slices/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";

function Sidebar() {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => setIsCollapsed(window.innerWidth <= 1300);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const user = useAppSelector((state: RootState) => {
        const firstName = state.auth.signInResponse?.User?.first_name || "";
        const lastName = state.auth.signInResponse?.User?.last_name || "";
        return `${firstName} ${lastName}`.trim();
    });

    const handleLogout = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        const accessToken = localStorage.getItem('accessToken');

        if (refreshToken && accessToken) {
            try {
                const signOutData = {
                    refresh_token: refreshToken,
                    access_token: accessToken
                };

                const response = await dispatch(signoutAsync(signOutData)).unwrap(); // Dispatch with one argument
                console.log('Logout successful:', response);

                localStorage.clear();
                navigate('/sign-in');
            } catch (error: any) {
                console.error('Logout failed:', error.message || error);
            }
        } else {
            console.warn('No tokens found in local storage.');
            navigate('/sign-in');
        }
    };



    const toggleSidebar = () => setIsCollapsed(!isCollapsed);

    return (
        <aside className={`sidebar bg-color-9 ${isCollapsed ? 'collapsed' : ''}`}>
            <div className="text-center mt-3">
                <Image src={Logo} />
            </div>
            <Row className="sidebar-name-card mt-4">
                <Col md={3}>
                    <Image src={User} />
                </Col>
                <Col md={9}>
                    <small className="sidebar-name">{user}</small>
                </Col>
            </Row>
            <div className='sidebar-border'></div>
            <div className="sidebar-menu">
                <Link
                    to="/dashboard"
                    className={`sidebar-link ${location.pathname === '/dashboard' ? 'active' : ''}`}
                >
                    <Image src={Overview} className="sidebar-icon" />
                    {!isCollapsed && <span className="sidebar-text">Overview</span>}
                </Link>

                <Link
                    to="/meal-planner"
                    className={`sidebar-link ${location.pathname === '/meal-planner' ? 'active' : ''}`}
                >
                    <Image src={MealPlanner} className="sidebar-icon" />
                    {!isCollapsed && <span className="sidebar-text">Meal Planner</span>}
                </Link>

                <Link
                    to="/reminders"
                    className={`sidebar-link ${location.pathname === '/reminders' ? 'active' : ''}`}
                >
                    <Image src={Reminders} className="sidebar-icon" />
                    {!isCollapsed && <span className="sidebar-text">Reminders</span>}
                </Link>

                <Link
                    to="/reports"
                    className={`sidebar-link ${location.pathname === '/reports' ? 'active' : ''}`}
                >
                    <Image src={Reports} className="sidebar-icon" />
                    {!isCollapsed && <span className="sidebar-text">Reports</span>}
                </Link>

                <Link
                    to="/calendar"
                    className={`sidebar-link ${location.pathname === '/calendar' ? 'active' : ''}`}
                >
                    <Image src={Calendar} className="sidebar-icon" />
                    {!isCollapsed && <span className="sidebar-text">Calendar</span>}
                </Link>

                <Link
                    to="/settings"
                    className={`sidebar-link ${location.pathname === '/settings' ? 'active' : ''}`}
                >
                    <Image src={Settings} className="sidebar-icon" />
                    {!isCollapsed && <span className="sidebar-text">Settings</span>}
                </Link>
            </div>

            <div className="mt-auto">
                <Button className="logout-btn border-0 py-3 text-start" onClick={toggleSidebar}>
                    <Image src={CollapseIcon} className="sidebar-icon" />
                    {!isCollapsed && <span className="logout-text">Collapse</span>}
                </Button>

                <Button className="logout-btn border-0 py-3 text-start" onClick={handleLogout}>
                    <Image src={Logout} className="sidebar-icon" />
                    {!isCollapsed && <span className="logout-text">Logout</span>}
                </Button>
            </div>
        </aside>
    );
}

export default Sidebar;
