import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    GetAllMealsResponse, Meals, getAllMeals, getAMeal, GetAMealResponse,
    CreateMealData, CreateMealResponse, createMeal, MealData,
    CreateWaterData, CreateWaterResponse, createWater, WaterData,
    GetDailyMealLogResponse, getDailyMealLog, DailyMealLog,
} from "services/meal-logging/mealService";

export interface MealState {
    getAllMealsResponse?: GetAllMealsResponse | null;
    getAMealResponse?: GetAMealResponse | null;
    createMealResponse?: CreateMealResponse | null;
    createWaterResponse?: CreateWaterResponse | null;
    getDailyMealLogResponse?: GetDailyMealLogResponse | null;
    meals: Meals[];
    aMeal: Meals | null;
    dailyMealLog: DailyMealLog[] | null;
    mealData: MealData | null;
    waterData: WaterData | null;
}

const initialState: MealState = {
    getAllMealsResponse: null,
    getAMealResponse: null,
    createMealResponse: null,
    createWaterResponse: null,
    getDailyMealLogResponse: null,
    dailyMealLog: null,
    meals: [],
    aMeal: null,
    mealData: null,
    waterData: null,
}

export const getAllMealsAsync = createAsyncThunk(
    'meals/getAllMeals',
    async ({ access_token, searchTerm }: { access_token: string; searchTerm: string | null }) => {
        try {
            const meals = await getAllMeals(access_token, searchTerm)
            return meals;
        } catch (error) {
            console.error('Error fetching meals:', error);
            throw error;
        }
    }
);
export const getAMealAsync = createAsyncThunk(
    'meals/getAMeal',
    async ({ access_token, id }: { access_token: string; id: string | null }) => {
        try {
            const aMeal = await getAMeal(access_token, id)
            return aMeal;
        } catch (error) {
            console.error('Error fetching a meal:', error);
            throw error;
        }
    }
);
export const createMealAsync = createAsyncThunk(
    'meals/createMeal',
    async ({ access_token, createMealData }: { access_token: string; createMealData: CreateMealData }) => {
        try {
            const response = await createMeal(access_token, createMealData)
            return response;
        } catch (error) {
            throw error;
        }
    }
);
export const createWaterAsync = createAsyncThunk(
    'meals/createWater',
    async ({ access_token, createWaterData }: { access_token: string; createWaterData: CreateWaterData }) => {
        try {
            const response = await createWater(access_token, createWaterData)
            return response;
        } catch (error) {
            throw error;
        }
    }
);
export const getDailyMealLogAsync = createAsyncThunk(
    'meals/getDailyMealLog',
    async ({ access_token, id }: { access_token: string; id: string | null }) => {
        try {
            const aMeal = await getDailyMealLog(access_token, id)
            return aMeal;
        } catch (error) {
            console.error('Error fetching a meal:', error);
            throw error;
        }
    }
);
const mealSlice = createSlice({
    name: "meal",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllMealsAsync.fulfilled, (state, action) => {
            state.meals = action.payload;
        });
        builder.addCase(getAMealAsync.fulfilled, (state, action) => {
            state.aMeal = action.payload;
        });
        builder.addCase(createMealAsync.fulfilled, (state, action) => {
            state.createMealResponse = action.payload;
        });
        builder.addCase(createWaterAsync.fulfilled, (state, action) => {
            state.createWaterResponse = action.payload;
        });
        builder.addCase(getDailyMealLogAsync.fulfilled, (state, action) => {
            state.getDailyMealLogResponse = action.payload;
        });
    }
})

export default mealSlice.reducer;