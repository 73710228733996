import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/auth/authSlice";
import mealReducer from "../slices/meal-logging/mealSlice"


const initialLoadingState = localStorage.getItem("loading") === "true";

const store = configureStore({
    reducer: {
        auth: authReducer,
        meal: mealReducer,
        loading: (state = initialLoadingState, action: { type: string; }) => {
            if (action.type.endsWith("/pending")) {
                localStorage.setItem("loading", "true");
                return true;
            } else if (
                action.type.endsWith("/fulfilled") ||
                action.type.endsWith("/rejected")
            ) {
                localStorage.removeItem("loading");
                return false;
            }
            return state;
        },
    },
});

// Exporting store types
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;