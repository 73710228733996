import React, { useState, useEffect, KeyboardEvent } from 'react';
import { Modal, Row, Container, Nav } from "react-bootstrap";
import Search from "../../../../../assets/images/search.png";
import Quantity from './quantity';
import { getAllMealsAsync, getAMealAsync } from 'slices/meal-logging/mealSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from "../../../../../store/store";

interface LunchProps {
    closeModal: () => void;
    show: boolean;
}

const Lunch: React.FC<LunchProps> = ({ closeModal, show }) => {
    const dispatch = useAppDispatch();
    const [searchInput, setSearchInput] = useState<string>("");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const access_token = useAppSelector((state: RootState) => state.auth.accessToken || "");
    const meals = useAppSelector((state: RootState) => state.meal.meals) || [];
    const selectedMeal = useAppSelector((state: RootState) => state.meal.aMeal);

    const [quantityModalOpen, setQuantityModalOpen] = useState<boolean>(false);
    const [addedMeals, setAddedMeals] = useState<{ name: string; id: number; portion_size: number; portion_size_metric: string; protein: number; calories: number; fat: number; carbohydrates: number; }[]>([]);
    const resetAddedMeals = () => {
        setAddedMeals([]);
    };

    const handleGetAllMeals = async (searchTerm: string | null) => {
        if (access_token) {
            const action = await dispatch(getAllMealsAsync({ access_token, searchTerm }));
            if (getAllMealsAsync.fulfilled.match(action)) {
                console.log('API Response:', action.payload);
            } else {
                console.error('Failed to fetch meals:', action.error);
            }
        } else {
            console.error("Access token is missing");
        }
    };

    useEffect(() => {
        if (searchQuery) {
            handleGetAllMeals(searchQuery);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, dispatch]);

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setSearchQuery(searchInput);
        }
    };
    const handleMealSelect = (mealId: number) => {
        if (access_token) {
            dispatch(getAMealAsync({ access_token, id: mealId.toString() }));
            setQuantityModalOpen(true);

        } else {
            console.error('Access token is missing');
        }
    };
    const [activeTab, setActiveTab] = useState<string>("new-food");
    const handleTabSelect = (key: string | null) => {
        if (key) {
            setActiveTab(key);
        }
    };

    const handleAddMeal = (meal: { name: string; id: number; portion_size: number; portion_size_metric: string; protein: number; calories: number; fat: number; carbohydrates: number; }) => {
        setAddedMeals(prevMeals => [...prevMeals, meal]);
        setQuantityModalOpen(false);
    };

    return (
        <Container fluid>
            <Modal
                centered
                show={show}
                onHide={closeModal}
                style={{ maxHeight: "100vh", overflowY: "auto" }}
            >
                <Modal.Header closeButton className="text-start mt-2 px-4">
                    <h4 className="dash-text-9">Lunch</h4>
                </Modal.Header>
                <Modal.Body className='px-4 add-modals'>
                    <Row>
                        <div className="border border-1 py-2 d-inline-flex justify-content-between custom-search-bar">
                            <img src={Search} alt="search icon" width={24} height={24} />
                            <input
                                type="search"
                                placeholder="Search"
                                className="bg-transparent border-0 text-color-5 mx-1 dash-text-1"
                                style={{ outline: "0", width: "100%" }}
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                onKeyDown={handleKeyPress}
                            />
                        </div>
                    </Row>

                    <div className='d-flex justify-content-center align-items-center mt-3'>
                        <Nav activeKey={activeTab} onSelect={handleTabSelect} className="nav2 justify-content-center">
                            <Nav.Item>
                                <Nav.Link
                                    className={`nav2-link-1 ${activeTab === "new-food" ? "active" : ""}`}
                                    eventKey="new-food"
                                    style={{ textDecoration: "none", color: "#83899F" }}
                                >
                                    <p className='nav2-text'>New Food</p>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    className={`nav2-link-2 ${activeTab === "my-food" ? "active" : ""}`}
                                    eventKey="my-food"
                                    style={{ textDecoration: "none", color: "#83899F" }}
                                >
                                    <p className='nav2-text'>My Food</p>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <Row className="mt-3">
                        {Array.isArray(meals) && meals.length > 0 ? (
                            meals.map((meal) => (
                                <p className="dash-text-20" key={meal.id} onClick={() => handleMealSelect(meal.id)}>
                                    {meal.meal_name}
                                </p>
                            ))
                        ) : (
                            <p className="dash-text-20">No meals found.</p>
                        )}
                    </Row>
                </Modal.Body>
            </Modal>
            <Quantity
                show={quantityModalOpen}
                closeModal={() => setQuantityModalOpen(false)}
                closeLunchModal={closeModal}
                meal={selectedMeal}
                onAddMeal={handleAddMeal}
                addedMeals={addedMeals}
                resetAddedMeals={resetAddedMeals}
            />

        </Container>
    );
};

export default Lunch;
