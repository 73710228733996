import React from 'react';
import { Container, Button, Image, Card } from 'react-bootstrap';
import VerifiedIcon from '../../../assets/images/verified-icon.png';
import { useNavigate } from 'react-router-dom';

const ResetSuccessful: React.FC = () => {
    const navigate = useNavigate();

    const backToSignIn = () => {
        navigate('/sign-in');
    }
    return (
        <Container fluid>
            <div className="auth-bg">
                <Card className='auth-card-4'>
                    <div className='text-center'>
                        <Image src={VerifiedIcon} className='mt-3' />
                        <h4 className='auth-text-1 mt-3'>Your Password has been <br /> reset sucessfully</h4>
                        <div className='d-grid mt-5'>
                            <Button type='submit' onClick={backToSignIn} className='bg-color-1 sign-up-btn border-0'>
                                Back to Login
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>

        </Container>
    );
};

export default ResetSuccessful;
