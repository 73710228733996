import React, { useState, useEffect } from 'react';
import { Container, Button, Image, Card } from "react-bootstrap";
import EmailIcon from "../../../../assets/images/email-icon.png";

function EmailVerification() {
    const [countdown, setCountdown] = useState(120);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prev) => {
                if (prev > 1) {
                    return prev - 1;
                } else {
                    setIsButtonDisabled(false);
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    return (
        <Container fluid>
            <div className="auth-bg">
                <Card className='auth-card-4'>
                    <div className='text-center'>
                        <Image src={EmailIcon} className='' />
                        <h4 className='auth-text-1 my-3'>Thank you !</h4>
                        <p className='auth-text-2'>
                            A verification link has been sent to the email <br /> address you used to register
                        </p>
                        <div className='d-grid sign-up-btn'>
                            <Button type='button'
                                className='bg-color-1  border-0'
                                disabled={isButtonDisabled}
                            >
                                Resend link
                            </Button>
                        </div>
                        <p className='mt-4'>{String(Math.floor(countdown / 60)).padStart(2, '0')}:{String(countdown % 60).padStart(2, '0')}</p>
                    </div>
                </Card>
            </div>
        </Container>)
}

export default EmailVerification