import React, { useState } from 'react';
import { Container, Card, Button, Nav } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import BackBtn from "../../../assets/images/back-btn.png";
import Arrow from "../../../assets/images/arrow.png";
import NextBtn from "../../../assets/images/next-btn.png";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from '../../../context/user/user-context';

interface WeightValues {
    weight: number;
    weight_unit: string;
}

function Weight() {
    const { updateUserDetails } = useUserContext();
    const [activeTab, setActiveTab] = useState<string>("kg");
    const [selectedWeight, setSelectedWeight] = useState<number | null>(1);
    const weights = Array.from({ length: 300 }, (_, i) => i + 1);
    const navigate = useNavigate();

    const handleTabSelect = (
        key: string | null,
        setFieldValue: (field: string, value: any) => void
    ) => {
        if (key) {
            setActiveTab(key);
            setFieldValue("weight_unit", key);
        }
    };

    const handleWeightClick = (
        weight: number,
        setFieldValue: (field: string, value: any) => void
    ) => {
        setSelectedWeight(weight);
        setFieldValue("weight", weight);
    };

    return (
        <Container fluid>
            <div className="auth-bg">
                <Card className="auth-card-3 text-center">
                    <Link to="/age" className='d-inline-flex justify-content-start px-2'>
                        <img src={BackBtn} alt="back" />
                    </Link>
                    <h4 className="auth-text-1 mt-2">What’s your weight?</h4>
                    <p className="auth-text-2 text-color-2">Please enter your current weight.</p>
                    <div className='d-flex justify-content-center mt-2'>
                        <ProgressBar now={50} className="custom-progress-bar" style={{ height: '4px', width: '157px' }} />;
                    </div>

                    <div>
                        <Formik
                            initialValues={{
                                weight: selectedWeight ?? 0,
                                weight_unit: activeTab,
                            }}
                            validationSchema={Yup.object().shape({
                                weight: Yup.number().required("Weight is required"),
                            })}
                            onSubmit={(values: WeightValues, { setSubmitting }) => {
                                console.log(values);
                                updateUserDetails({
                                    user_health_info: {
                                        weight: selectedWeight ?? 0,
                                        weight_unit: activeTab,
                                        height: 0,
                                        height_unit: '',
                                    }
                                });
                                setSubmitting(false);
                                navigate("/height")
                            }}
                        >
                            {({ handleSubmit, setFieldValue }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <Nav activeKey={activeTab} onSelect={(key) => handleTabSelect(key, setFieldValue)} className="nav justify-content-center">
                                            <Nav.Item>
                                                <Nav.Link
                                                    className={`nav-link-1 ${activeTab === "kg" ? "active" : ""}`}
                                                    eventKey="kg"
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#FFFFFF",
                                                    }}
                                                >
                                                    <p className='nav-text'>kg</p>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link
                                                    className={`nav-link-2 ${activeTab === "lb" ? "active" : ""}`}
                                                    eventKey="lb"
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#FFFFFF",
                                                    }}
                                                >
                                                    <p className='nav-text'>lb</p>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <div>
                                        {selectedWeight !== null && (
                                            <div className="text-center mt-3">
                                                <h3 className='selected-age'>{selectedWeight}</h3>
                                            </div>
                                        )}
                                        <div className='mt-4'>
                                            <img src={Arrow} alt='arrow' />
                                        </div>
                                        <div className="age-selector-container mt-5">
                                            <div className="age-items">
                                                {weights.map((weight) => (
                                                    <Button
                                                        key={weight}
                                                        variant={selectedWeight === weight ? "#008080" : "#008080"}
                                                        className="age-button text-color-3"
                                                        onClick={() => handleWeightClick(weight, setFieldValue)}
                                                    >
                                                        {weight}
                                                    </Button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-3 p-4">
                                        <div>
                                            <p className="auth-text-3">
                                                To create your personalized plan, we use <br />
                                                BMI to calculate your calorie budget. This <br />
                                                requires your weight, height, biological sex, <br />
                                                and age as inputs
                                            </p>
                                        </div>
                                        <div>
                                            <Button type='submit' className='border-0 bg-color-6 p-0'>
                                                <img src={NextBtn} alt="next" />
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Card>
            </div>
        </Container>
    );
}

export default Weight;
