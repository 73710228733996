import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import "./assets/style/style.css";
import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from "./pages/landing-page/landing-page";
import SignUp from './pages/auth/sign-up/sign-up';
import Age from './pages/auth/sign-up/age';
import Weight from './pages/auth/sign-up/weight';
import Height from './pages/auth/sign-up/height';
import SignIn from './pages/auth/sign-in';
import EmailVerification from './pages/auth/sign-up/verification/email-verification';
import VerificationSuccessful from './pages/auth/sign-up/verification/verification-successful';
import ForgotPassword from './pages/auth/forgot-password/forgot-password';
import PasswordVerification from 'pages/auth/forgot-password/password-verification';
import ResetPassword from './pages/auth/forgot-password/reset-password';
import ResetSuccessful from './pages/auth/forgot-password/reset-successful';
import Dashboard from './pages/dasboard/overview/overview';
import Report from 'pages/dasboard/report/report';
import { UserProvider } from './context/user/user-context';
import { useAppDispatch } from 'store/hooks';
import { setAccessToken, setRefreshToken, setUser } from 'slices/auth/authSlice';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const accessToken = localStorage.getItem('accessToken') || "";
    const refreshToken = localStorage.getItem('refreshToken') || "";

    if (user) {
      dispatch(setUser(user));
    }
    if (accessToken) {
      dispatch(setAccessToken(accessToken));
    }
    if (refreshToken) {
      dispatch(setRefreshToken(refreshToken));
    }
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/landing-page" element={<LandingPage />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/age" element={<Age />} />
        <Route path="/weight" element={<Weight />} />
        <Route path="/height" element={<Height />} />
        <Route path="/" element={<SignIn />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/verification-successful" element={<VerificationSuccessful />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password-verification" element={<PasswordVerification />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-successful" element={<ResetSuccessful />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/reports" element={<Report />} />
      </Routes>
    </Router>
  );
};

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <UserProvider>
        <App />
      </UserProvider>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();