import React, { useState } from 'react';
import { Container, Card, Button, Nav, Col, Row } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import BackBtn from "../../../assets/images/back-btn.png";
import Arrow from "../../../assets/images/right-arrow.png";
import { useUserContext } from '../../../context/user/user-context';
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks";
import { signupAsync } from "../../../slices/auth/authSlice";

interface HeightValues {
    height: number;
    height_unit: string;
}

function Height() {
    const {
        first_name,
        last_name,
        email,
        sex_at_birth,
        country_of_residence,
        ethnicity,
        password,
        confirm_password,
        terms,
        age,
        user_health_info,
        updateUserDetails
    } = useUserContext();

    const dispatch = useAppDispatch();

    const [activeTab, setActiveTab] = useState<string>(user_health_info.height_unit || "cm");
    const [selectedHeight, setSelectedHeight] = useState<number | null>(user_health_info.height || 1);

    const heights = Array.from({ length: 300 }, (_, i) => i + 1);
    const navigate = useNavigate();

    const handleTabSelect = (
        key: string | null,
        setFieldValue: (field: string, value: any) => void
    ) => {
        if (key) {
            setActiveTab(key);
            setFieldValue("height_unit", key);
        }
    };

    const handleHeightClick = (
        height: number,
        setFieldValue: (field: string, value: any) => void
    ) => {
        setSelectedHeight(height);
        setFieldValue("height", height);
    };

    return (
        <Container fluid>
            <div className="auth-bg">
                <Card className="auth-card-3 text-center">
                    <Link to="/weight" className='d-inline-flex justify-content-start px-2'>
                        <img src={BackBtn} alt="back" />
                    </Link>
                    <h4 className="auth-text-1 mt-2">What’s your height?</h4>
                    <p className="auth-text-2 text-color-2">Please enter your current height.</p>
                    <div className='d-flex justify-content-center mt-2'>
                        <ProgressBar now={75} className="custom-progress-bar" style={{ height: '4px', width: '157px' }} />;
                    </div>
                    <Formik
                        initialValues={{
                            height: selectedHeight ?? 0,
                            height_unit: activeTab,
                        }}
                        validationSchema={Yup.object().shape({
                            height: Yup.number().required("Height is required"),
                        })}
                        onSubmit={async (values: HeightValues, { setSubmitting }) => {
                            const updatedUserInfo = {
                                first_name,
                                last_name,
                                email,
                                sex_at_birth,
                                country_of_residence,
                                ethnicity,
                                password,
                                confirm_password,
                                terms,
                                age,
                                user_health_info: {
                                    ...user_health_info,
                                    height: values.height,
                                    height_unit: values.height_unit,
                                },
                            };
                            updateUserDetails(updatedUserInfo);
                            try {
                                const response = await dispatch(signupAsync(updatedUserInfo)).unwrap();
                                console.log("Signup successful:", response);
                                navigate('/email-verification');
                            } catch (error) {
                                console.error("Signup failed:", error);
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ handleSubmit, setFieldValue }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <Nav activeKey={activeTab} onSelect={(key) => handleTabSelect(key, setFieldValue)} className="nav justify-content-center">
                                        <Nav.Item>
                                            <Nav.Link
                                                className={`nav-link-1 ${activeTab === "cm" ? "active" : ""}`}
                                                eventKey="cm"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "#FFFFFF",
                                                }}
                                            >
                                                <p className='nav-text'>cm</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                className={`nav-link-2 ${activeTab === "ft" ? "active" : ""}`}
                                                eventKey="ft"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "#FFFFFF",
                                                }}
                                            >
                                                <p className='nav-text'>ft</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <div className='mt-4 px-5'>
                                    <Row>
                                        <Col xs={8} md={8} className='mt-5 pt-5'>
                                            <Row>
                                                <div className='d-flex align-items-center'>
                                                    <Col md={2} xs={2}></Col>
                                                    <Col md={8} xs={8}>
                                                        {selectedHeight !== null && (
                                                            <h3 className="selected-age ">
                                                                {selectedHeight}
                                                            </h3>
                                                        )}
                                                    </Col>
                                                    <Col md={2} xs={2}>
                                                        <img src={Arrow} alt="arrow" className="" />
                                                    </Col>
                                                </div>
                                            </Row>
                                            <div>
                                                <p className="auth-text-3 mt-5">
                                                    To create your personalized plan, we use <br />
                                                    BMI to calculate your calorie budget. This <br />
                                                    requires your weight, height, biological sex, <br />
                                                    and age as inputs
                                                </p>
                                            </div>
                                        </Col>

                                        <Col xs={4} md={4}>
                                            <div className="height-selector-container">
                                                {heights.map((height) => (
                                                    <Button
                                                        key={height}
                                                        variant={selectedHeight === height ? "#008080" : "#008080"}
                                                        className="height-button text-color-3"
                                                        onClick={() => handleHeightClick(height, setFieldValue)}
                                                    >
                                                        {height}
                                                    </Button>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="d-grid mt-4 sign-up-btn">
                                        <Button
                                            type="submit"
                                            className="border-0 bg-color-1 "
                                        >
                                            <span className="text-center">Create Account</span>
                                        </Button>
                                    </div>
                                </div>


                            </Form>
                        )}
                    </Formik>
                </Card>
            </div>
        </Container>
    );
}

export default Height;
