import React, { useState, useEffect } from 'react';
import { Image, Row, Col, Container, Card } from "react-bootstrap";
import Bell from "../../assets/images/bell.png";
import Setting from "../../assets/images/setting.png";
import CalBg from "../../assets/images/cal-goal-bg.png";
import datePicker from "../../assets/images/date-picker.png";
import greenBell from "../../assets/images/green-bell.png";
import redBell from "../../assets/images/red-bell.png";
import checkBox from "../../assets/images/check-box.png";
import MenuIcon from '@mui/icons-material/Menu';


function RightSidebar() {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1050) {
                setIsCollapsed(true);
                setIsSidebarOpen(false);
            } else {
                setIsCollapsed(false);
                setIsSidebarOpen(true);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen((prevState) => !prevState);
    };

    return (
        <>
            {isCollapsed && (
                <button className="hamburger" onClick={toggleSidebar}>
                    <MenuIcon />
                </button>
            )}
            <aside
                className={`right-sidebar bg-color-9 ${isSidebarOpen ? 'open' : 'closed'}`}
            >
                <Container fluid className=''>
                    <Row>
                        <Col className='mt-3'>
                            <Image src={Bell} />
                        </Col>
                        <Col className='mt-3'>
                            <Image src={Setting} />
                        </Col>
                        <Col className='mt-3'>
                        </Col>
                    </Row>
                    <Card className='mt-5'>
                        <Image src={CalBg} />
                    </Card>
                    <Row className='mt-5'>
                        <Image src={datePicker} />
                    </Row>
                    <div className='sidebar-border'></div>
                    <div>
                        <p className='text-color-7 dash-text-7 mt-3'>Upcoming</p>
                        <Row className='mt-4'>
                            <Col md={2} sm={2} xs={2}>
                                <Image src={greenBell} width={25} height={25} />
                            </Col>
                            <Col md={8} sm={8} xs={8} className='mt-1'>
                                <p className='dash-text-17'>Morning Jog</p>
                            </Col>
                            <Col md={2} sm={2} xs={2}>
                                <Image src={checkBox} width={30} height={30} className='float-end' />
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col md={2} sm={2} xs={2}>
                                <Image src={redBell} width={25} height={25} />
                            </Col>
                            <Col md={8} sm={8} xs={8} className='mt-1'>
                                <p className='dash-text-17'>Meeting with Tok</p>
                            </Col>
                            <Col md={2} sm={2} xs={2}>
                                <Image src={checkBox} width={30} height={30} className='float-end' />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </aside>
        </>
    )
}

export default RightSidebar