import React, { useState } from 'react';
import Layout from 'components/layouts/layout'
import { Card, Col, Row, Nav } from 'react-bootstrap'
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


function Report() {
    const calData = [
        { name: 'JAN', uv: 1000 },
        { name: 'FEB', uv: 3000 },
        { name: 'MAR', uv: 2000 },
        { name: 'APR', uv: 1500 },
        { name: 'MAY', uv: 2500 },
        { name: 'JUN', uv: 4000 },
        { name: 'JUL', uv: 500 },
        { name: 'AUG', uv: 3500 },
        { name: 'SEP', uv: 1000 },
        { name: 'OCT', uv: 2000 },
        { name: 'NOV', uv: 500 },
        { name: 'DEC', uv: 4000 },
    ];
    const waterData = [
        { name: 'Sun', uv: 1 },
        { name: 'Mon', uv: 3 },
        { name: 'Tue', uv: 2 },
        { name: 'Wed', uv: 6 },
        { name: 'Thu', uv: 4 },
        { name: 'Fri', uv: 3 },
        { name: 'Sat', uv: 5 },
    ];
    const [activeTab, setActiveTab] = useState<string>("monthly");
    const handleTabSelect = (key: string | null) => {
        if (key) {
            setActiveTab(key);
        }
    };
    return (
        <div>
            <Layout>
                <div className='p-3 dash-bg'>
                    <h6 className='dash-text-1'><span className='text-color-4'>Pages /</span> Reports</h6>
                    <h5 className='dash-text-2'>Reports</h5>
                    <p className='dash-text-1'>
                        Track. Analyze. Transform: Your Journey, Visualized
                    </p>
                    <Row>
                        <Card className='no-border'>
                            <Row className='p-3'>
                                <Col>
                                    <p className='dash-text-22'>Calorie Consumption</p>
                                </Col>
                                <Col>
                                    <div className='float-end'>
                                        <Nav activeKey={activeTab} onSelect={handleTabSelect} className="report-nav justify-content-center">
                                            <Nav.Item>
                                                <Nav.Link
                                                    className={`report-nav-link ${activeTab === "weekly" ? "active" : ""}`}
                                                    eventKey="weekly"
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <p className='report-nav-text'>Weekly</p>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link
                                                    className={`report-nav-link ${activeTab === "monthly" ? "active" : ""}`}
                                                    eventKey="monthly"
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <p className='report-nav-text'>Monthly</p>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link
                                                    className={`report-nav-link ${activeTab === "yearly" ? "active" : ""}`}
                                                    eventKey="yearly"
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <p className='report-nav-text'>Yearly</p>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <div style={{ width: '100%', height: 300 }}>
                                    <ResponsiveContainer>
                                        <BarChart data={calData} margin={{
                                            top: 5,
                                            right: 30,
                                            left: 0,
                                            bottom: 5,
                                        }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" scale="point" padding={{ left: 15, right: 10 }} className='dash-text-25' />
                                            <YAxis className='dash-text-25' />
                                            <Tooltip />
                                            <Bar dataKey="uv" fill="#41aeba" background={{ fill: '#eee' }} barSize={10} /> {/* Adjust barSize here */}
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </Card>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Card className='no-border p-3'>
                                <Row className=''>
                                    <Col>
                                        <p className='dash-text-22'>Activity Monitor</p>
                                    </Col>
                                </Row>
                                <Row className=''>
                                    <Col>
                                        <PieChart
                                            series={[
                                                {
                                                    data: [
                                                        { id: 0, value: 30, color: '#04afde' }, // First layer color
                                                    ],
                                                    innerRadius: 50,
                                                    outerRadius: 60,
                                                },
                                                {
                                                    data: [
                                                        { id: 1, value: 40, color: '#ffe500' }, // Second layer color
                                                    ],
                                                    innerRadius: 70,
                                                    outerRadius: 80,
                                                },
                                                {
                                                    data: [
                                                        { id: 2, value: 15, color: '#5BA7AB' }, // Third layer color
                                                    ],
                                                    innerRadius: 90,
                                                    outerRadius: 100,
                                                },
                                            ]}
                                            width={300}
                                            height={300}
                                        />
                                    </Col>
                                    <Col className='mt-5 pt-5'>
                                        <div>
                                            <span className='label-dots report-color-3 mt-5'></span>Calories
                                        </div>
                                        <div>
                                            <span className='label-dots report-color-2 mt-3'></span>Water
                                        </div>
                                        <div>
                                            <span className='label-dots report-color-1 mt-3'></span>Steps
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='no-border p-3'>
                                <Row>
                                    <Col>
                                        <p className='dash-text-22'>Water Consumption </p>
                                    </Col>
                                </Row>
                                <div style={{ width: '100%', height: 300 }}>
                                    <ResponsiveContainer>
                                        <BarChart data={waterData} margin={{
                                            top: 5,
                                            right: 30,
                                            left: 0,
                                            bottom: 5,
                                        }}>
                                            <XAxis dataKey="name" scale="point" padding={{ left: 20, right: 10 }} className='dash-text-25' />
                                            <YAxis className='dash-text-25' />
                                            <Tooltip />
                                            <Bar dataKey="uv" fill="#41aeba" background={{ fill: '#eee' }} barSize={35} /> {/* Adjust barSize here */}
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Card className='no-border p-3'>
                                <Row>
                                    <Col>
                                        <p className='dash-text-22'>Calorie Breakdown </p>
                                    </Col>
                                </Row>
                                <div className='d-flex justify-content-center align-items-center' style={{ height: '100%' }}>
                                    <PieChart
                                        series={[{
                                            data: [
                                                { id: 0, value: 24, color: '#FF0000' }, { id: 1, value: 20, color: '#0A1B39' }, { id: 2, value: 25, color: '#FFE500' }, { id: 3, value: 25, color: '#ffe0fa' }, { id: 4, value: 11, color: '#008080' }
                                            ],
                                            innerRadius: 79
                                        }]}
                                        width={400}
                                        height={400}
                                    />
                                </div>
                                <Row className='px-4'>
                                    <div>
                                        <span className='label-dots report-color-4 mt-3'></span>
                                        <small className='dash-text-23'>Carbohydrates</small>
                                        <span className='float-end mt-2 dash-text-24'>1234Kcal</span>
                                    </div>
                                    <div>
                                        <span className='label-dots report-color-5 mt-3'></span>
                                        <small className='dash-text-23'>Protein</small>
                                        <span className='float-end mt-2 dash-text-24'>1200Kcal</span>
                                    </div>
                                    <div>
                                        <span className='label-dots report-color-6 mt-3'></span>
                                        <small className='dash-text-23'>Fats and Oils</small>
                                        <span className='float-end mt-2 dash-text-24'>480Kcal</span>
                                    </div>
                                    <div>
                                        <span className='label-dots report-color-7 mt-3'></span>
                                        <small className='dash-text-23'>Minerals</small>
                                        <span className='float-end mt-2 dash-text-24'>560Kcal</span>
                                    </div>
                                    <div>
                                        <span className='label-dots report-color-8 mt-3'></span>
                                        <small className='dash-text-23'>Vitamins</small>
                                        <span className='float-end mt-2 dash-text-24'>890Kcal</span>
                                    </div>
                                </Row>
                            </Card>
                        </Col>

                        <Col>
                            <Card className='no-border p-3'>
                                <Row>
                                    <Col>
                                        <p className='dash-text-22'></p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </div>
    )
}

export default Report