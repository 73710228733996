import React from 'react'
import { Container, Button, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeaturesImg from "../../assets/images/home-feature-img.png";
import HomeTick from "../../assets/images/home-tick-circle.png"

function LandingPage() {
    return (
        <div>
            <Container fluid>
                <section className="home-bg d-none d-md-block">
                    {/* <video autoPlay loop muted className="video-background">
                        <source src={HomeBgVid} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
                    <div className='home-text'>
                        <h3 className='home-header'>NUTIENT</h3>
                        <p className='home-p text-center'>Track your calories, reach your <br /> fitness goals, and live a <br /> healthier life</p>
                        <Link to="/sign-up" className='link-style'>
                            <Button type='submit' className='home-btn'>Join Waitlist Now</Button>
                        </Link>
                    </div>
                </section>
                <section>
                    <Row>
                        <Col>
                            <Image src={FeaturesImg} />
                        </Col>
                        <Col>
                            <h6 className='text-center'>
                                Your Complete Health and Fitness Companion
                            </h6>
                            <span>
                                <Image src={HomeTick} /> Calorie Tracking Made Easy
                            </span>
                            <p>Log your meals and track calories effortlessly with our extensive food database.</p>
                        </Col>
                    </Row>
                </section>
            </Container>
        </div>
    )
}

export default LandingPage;