import React, { useState } from 'react';
import { Modal, Row, Col, Container, Button, Dropdown } from "react-bootstrap";
import AddMeal from './add-meal';
import Arrow from "../../../../../assets/images/drop-down-arrow.png";
import { TextField } from '@mui/material';
import { Meals } from 'services/meal-logging/mealService';

interface QuantityProps {
    closeModal: () => void;
    closeDinnerModal: () => void;
    show: boolean;
    meal: Meals | null;
    onAddMeal: (meal: { name: string; id: number; portion_size: number; portion_size_metric: string; protein: number; calories: number; fat: number; carbohydrates: number; }) => void;
    addedMeals: { name: string; id: number; portion_size: number; portion_size_metric: string; protein: number; calories: number; fat: number; carbohydrates: number; }[];
    resetAddedMeals: () => void;
}

const Quantity: React.FC<QuantityProps> = ({ closeModal, show, meal, onAddMeal, addedMeals, closeDinnerModal, resetAddedMeals }) => {
    const [addMealModalOpen, setAddMealModalOpen] = useState<boolean>(false);
    const [selectedMetric, setSelectedMetric] = useState<string | null>(null);
    const [quantity, setQuantity] = useState<number | ''>('');
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    const handleOpenAddMealModal = () => {
        setAddMealModalOpen(true);
        closeModal();
    };
    const handleCloseAddMealModal = () => setAddMealModalOpen(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleMetricSelect = (metric: string) => {
        setSelectedMetric(metric);
        setDropdownOpen(false);
    };

    const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setQuantity(value ? parseInt(value) : '');
    };

    const handleSubmit = () => {
        if (!quantity || !selectedMetric || !meal) {
            console.error("Quantity, metric, or meal data missing.");
            return;
        }

        const baseAmount = meal.available_metrics[selectedMetric];

        const calculatedCalories = Math.ceil(meal.base_nutrition.calories * (quantity / baseAmount));
        const calculatedProtein = Math.ceil(meal.base_nutrition.protein * (quantity / baseAmount));
        const calculatedFat = Math.ceil(meal.base_nutrition.fat * (quantity / baseAmount));
        const calculatedCarbohydrates = Math.ceil(meal.base_nutrition.carbohydrates * (quantity / baseAmount));

        onAddMeal({
            name: meal.meal_name || "Unknown Meal",
            id: meal.id || 0,
            portion_size: quantity,
            portion_size_metric: selectedMetric,
            protein: calculatedProtein,
            calories: calculatedCalories,
            fat: calculatedFat,
            carbohydrates: calculatedCarbohydrates,
        });

        handleOpenAddMealModal();
    };

    const handleAddMore = () => {
        setAddMealModalOpen(false);
        setQuantity('');
        setSelectedMetric(null);
    };

    return (
        <Container fluid className="bg-color-6">
            <Modal
                centered
                show={show}
                size='sm'
                onHide={closeModal}
                style={{ maxHeight: "100vh", overflowY: "auto" }}
            >
                <Modal.Body className='px-4 text-center'>
                    <h4 className='dash-text-10'>{meal?.meal_name}</h4>
                    <Row className='mt-3'>
                        <Col md={3}>
                            <h5 className='dash-text-11 '>{meal?.base_nutrition?.calories}</h5>
                            <p className='dash-text-1'>Calories</p>
                        </Col>
                        <Col md={3}>
                            <h5 className='dash-text-11 '>{meal?.base_nutrition?.protein}</h5>
                            <p className='dash-text-1'>Proteins</p>
                        </Col>
                        <Col md={3}>
                            <h5 className='dash-text-11 '>{meal?.base_nutrition?.fat}</h5>
                            <p className='dash-text-1'>Fats</p>
                        </Col>
                        <Col md={3}>
                            <h5 className='dash-text-11 '>{meal?.base_nutrition?.carbohydrates}</h5>
                            <p className='dash-text-1'>Carbs</p>
                        </Col>
                    </Row>
                    <Row className='mt-2 d-flex justify-content-center'>
                        <Col xs="auto">
                            <TextField
                                type="number"
                                variant="standard"
                                value={quantity}
                                onChange={handleQuantityChange}
                                slotProps={{
                                    inputLabel: {
                                        shrink: true,
                                    },
                                }}
                                className='quantity-border'
                            />
                        </Col>
                        <Col xs="auto">
                            <div className='d-flex'>
                                <p className='text-color-1 mt-3 dash-text-11'>{selectedMetric || 'Select Metric'}</p>
                                <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
                                    <Dropdown.Toggle variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                        <img src={Arrow} alt="Arrow" className='mt-3' style={{ margin: '0', padding: '0', width: "100%", height: "100%" }} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {Object.keys(meal?.available_metrics || {}).map((metric, index) => (
                                            <Dropdown.Item key={index} onClick={() => handleMetricSelect(metric)}>
                                                {metric}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-between float-end'>
                        <Col>
                            <Button onClick={closeModal} className='text-color-1 border-0 bg-color-6 dash-text-19'> Cancel</Button>
                        </Col>
                        <Col>
                            <Button onClick={handleSubmit} className='text-color-1 border-0 bg-color-6 dash-text-19'> Ok</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <AddMeal
                show={addMealModalOpen}
                closeModal={handleCloseAddMealModal}
                closeDinnerModal={closeDinnerModal}
                meals={addedMeals}
                onAddMore={handleAddMore}
                resetAddedMeals={resetAddedMeals}
            />
        </Container>
    )
}

export default Quantity